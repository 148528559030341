export default function GoogleAnalytics(): JSX.Element {
  if (!process.env.NEXT_PUBLIC_KLAVIYO_TOKEN) return <></>;

  return (
    <>
      <script
        async
        type='application/javascript'
        src={'//static.klaviyo.com/onsite/js/klaviyo.js?company_id=' + process.env.NEXT_PUBLIC_KLAVIYO_TOKEN}
      />
    </>
  );
}
