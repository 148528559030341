import { css, Global } from '@emotion/react';

const chromeBugStyle = css`
  body.loading * {
    transition: none !important;
  }
`;

const ChromeBug = () => (
  <>
    <Global styles={chromeBugStyle} />
  </>
);

export default ChromeBug;
