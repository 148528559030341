import { css, Global } from '@emotion/react';
import ChromeBug from '@styles/CgromeBug';
import tw, { GlobalStyles as BaseStyles } from 'twin.macro';

const customStyles = css`
  html {
    ${tw`font-inter`}
    ${tw`bg-brand-background`}
    scroll-behavior: smooth;
  }

  html[dir='rtl'] {
    // Applying Arabic Font
    ${tw`font-noto`}
  }
`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <Global styles={customStyles} />
    <ChromeBug />
  </>
);

export default GlobalStyles;
