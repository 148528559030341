
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import GoogleAnalytics from '@analytics/google/google';
import Klaviyo from '@analytics/klaviyo/klaviyo';
import * as MixpanelGeneralActions from '@analytics/mixpanel/actions/General';
import Mixpanel from '@analytics/mixpanel/Mixpanel';
import useKeepAlive from '@analytics/useKeepAlive';
import FaviconLinks from '@common/head/favicon-links';
import OtherMetaTags from '@common/head/other-meta-tags';
import { YelaContextProvider } from '@hooks/useYela';
import GlobalStyles from '@styles/GlobalStyles';
import * as gtag from 'g.analytics';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

const YelaApp = ({ Component, pageProps }: AppProps): JSX.Element => {
  const router = useRouter();
  useKeepAlive();
  Mixpanel.init();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_HOTJAR_ID) {
      import('react-hotjar')
        .then(hotjarLib => {
          hotjarLib.hotjar.initialize(+process.env.NEXT_PUBLIC_HOTJAR_ID, 6);
        })
        .catch(e => {
          console.log(e);
        });
    }

    smoothscroll.polyfill();

    MixpanelGeneralActions.onAppInit({ router });

    window.addEventListener(`beforeunload`, e => {
      e.preventDefault();
      MixpanelGeneralActions.onAppClose({ router });
    });
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      gtag.pageview(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <meta charSet='UTF-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1' />
        <OtherMetaTags />
        <FaviconLinks />
        <GoogleAnalytics />
        <Klaviyo />
      </Head>
      <GlobalStyles />
      <YelaContextProvider>
        <Component {...pageProps} />
      </YelaContextProvider>
    </>
  );
};

const __Page_Next_Translate__ = YelaApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  