import { useInterval } from '@hooks/useInterval';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { onKeepAlive } from './mixpanel/actions/General';

type KeepAlive = {
  clicked: boolean;
  scrolled: boolean;
  touchStarted: boolean;
};

const useKeepAlive = (): void => {
  const interval = parseInt(process.env.NEXT_PUBLIC_KEEP_ALIVE) || null;
  const router = useRouter();
  const [state, setState] = useState<KeepAlive>({
    clicked: false,
    scrolled: false,
    touchStarted: false,
  });

  const onClicked = () => {
    setState(current => ({ ...current, clicked: true }));
  };

  const onScrolled = () => {
    setState(current => ({ ...current, scrolled: true }));
  };

  const onTouchStarted = () => {
    setState(current => ({ ...current, touchStarted: true }));
  };

  useEffect(() => {
    if (typeof window == 'undefined' || interval == null) return;

    window.addEventListener('click', onClicked);
    window.addEventListener('scroll', onScrolled);
    window.addEventListener('touchstart', onTouchStarted);

    return () => {
      window.removeEventListener('click', onClicked);
      window.removeEventListener('scroll', onScrolled);
      window.removeEventListener('touchstart', onTouchStarted);
    };
  }, []);

  useInterval(() => {
    if (interval == null) return;
    if (!state.clicked && !state.scrolled && !state.touchStarted) return;

    setState({
      clicked: false,
      scrolled: false,
      touchStarted: false,
    });

    onKeepAlive({ router, ...state });
  }, interval);
};

export default useKeepAlive;
